import { type Middleware } from '@nuxt/types'
import { Auth } from '@aws-amplify/auth'
import { studentRepo } from '@/repos/student'
import { jukuConfigRepo } from '@/repos/jukuConfig'

const EXCLUDED_NAMES = ['sign-in', 'sign-up']

export default <Middleware>async function ({ route, store, redirect, $logger }) {
  if (EXCLUDED_NAMES.includes(route.name as string)) return
  if (route.name === 'line-link-sign-in') return

  try {
    const { username } = await Auth.currentAuthenticatedUser()

    const user = await studentRepo.fetchSignedIn(username)
    store.commit('user/set', user)
    // jukuConfigをセット
    const jukuConfig = await jukuConfigRepo.fetch(user.jukuId)
    store.commit('jukuConfig/set', jukuConfig)

    // sentryにusernameを送る
    $logger.identify(user.username, {
      id: user.id,
      username: user.username,
      jukuId: user.jukuId
    })
  } catch (err) {
    console.error(err)
    redirect('/sign-in')
  }
}
