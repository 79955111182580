import type { MutationTree, ActionTree } from 'vuex'
import { Auth } from '@aws-amplify/auth'
import { type State, type UserState } from '@/student/store/userStateType'
import { studentRepo } from '@/repos/student'

export const state = (): UserState =>
  ({
    __typename: 'Student',
    id: -1,
    jukuId: -1,
    userId: -1,
    username: '',
    changedPasswordFlag: false,
    name: '',
    furigana: '',
    gender: 'male'
  }) as UserState

export const mutations: MutationTree<UserState> = {
  set(s, user) {
    Object.assign(s, { ...user })
  },
  reset(s) {
    Object.assign(s, state())
  }
}

export const actions: ActionTree<UserState, State> = {
  async signIn({ commit }, data) {
    // CognitoUser取得
    // TODO: 仕様まちだが、認可の関係でログインの種類をconfigに乗せてauthを叩く
    await Auth.signIn(data.username, data.password)
    const user = await studentRepo.fetchSignedIn(data.username)
    commit('set', user)
  },
  async signOut({ commit, dispatch }) {
    await Auth.signOut()
    commit('reset')
    dispatch('jukuConfig/reset', undefined, { root: true })
  }
}
