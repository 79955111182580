import { render, staticRenderFns } from "./default.vue?vue&type=template&id=0ff0d95e"
import script from "./default.vue?vue&type=script&lang=ts"
export * from "./default.vue?vue&type=script&lang=ts"
import style0 from "./default.vue?vue&type=style&index=0&id=0ff0d95e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavigationDrawerProfile: require('/builds/mates-pay/app/new_reco/reco_for_parent/components/profiles/NavigationDrawerProfile.vue').default,SideMenuPc: require('/builds/mates-pay/app/new_reco/reco_for_parent/student/fragments/SideMenuPc.vue').default,FooterSp: require('/builds/mates-pay/app/new_reco/reco_for_parent/student/fragments/FooterSp.vue').default,Snackbar: require('/builds/mates-pay/app/new_reco/reco_for_parent/components/Snackbar.vue').default})
