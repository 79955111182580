
import { computed, defineComponent, inject, useRouter } from '@nuxtjs/composition-api'
import type { PropType } from 'vue'
import { useStore } from '@/useCases/useStore'
import { NotificationsInjectionKey, type SideNavOptions } from '@/student/layouts/default.vue'

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<ReadonlyArray<SideNavOptions>>,
      required: true
    }
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const signOut = async () => {
      try {
        await store.dispatch('user/signOut')
        await router.push('/sign-in')
      } catch (err) {
        console.error(err)
      }
    }
    const notifications = inject(NotificationsInjectionKey)!
    const unreadNotifications = computed(() => {
      return notifications.value.filter(notification => !notification.readDate)
    })

    return {
      signOut,
      unreadNotifications
    }
  }
})
