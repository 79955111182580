import { gql } from '@apollo/client/core'
import { ERROR_NOT_FOUND, apolloClient } from '@/apis/graphql/client'
import { registerReadNotificationForStudent } from '@/apis/graphql/query/mutations'
import { getStudentReadNotificationById, getStudentReadNotifications } from '@/apis/graphql/query/queries'
import {
  type GetStudentReadNotificationsResponse,
  type GetStudentReadNotificationsQuery,
  type RegisterReadNotificationForStudentMutation,
  type RegisterReadNotificationStudentInput,
  type GetStudentReadNotificationByIdQuery,
  type GetStudentReadNotificationByIdResponse
} from '@/types/codegen/schema'

export namespace StudentReadNotificationRepoResponse {
  export type fetchByStudentId = GetStudentReadNotificationsResponse
  export type fetchById = GetStudentReadNotificationByIdResponse
}

export const studentReadNotificationRepo = {
  fetchById: async (studentReadNotificationId: number) => {
    const { data } = await apolloClient.query<GetStudentReadNotificationByIdQuery>({
      query: gql(getStudentReadNotificationById),
      variables: {
        studentReadNotificationId
      }
    })
    if (!data || !data.getStudentReadNotificationById) throw ERROR_NOT_FOUND
    return data.getStudentReadNotificationById
  },
  fetchByStudentId: async (studentId: number) => {
    const { data } = await apolloClient.query<GetStudentReadNotificationsQuery>({
      query: gql(getStudentReadNotifications),
      variables: {
        studentId
      }
    })
    if (!data || !data.getStudentReadNotifications) throw ERROR_NOT_FOUND
    return data.getStudentReadNotifications
  },
  read: async (input: RegisterReadNotificationStudentInput) => {
    await apolloClient.mutate<RegisterReadNotificationForStudentMutation>({
      mutation: gql(registerReadNotificationForStudent),
      variables: { input }
    })
  }
}
